import React from 'react'
import Layout from '../../components/Default/Layout'
import ServiceHeader from '../../components/Services/ServiceOverview/ServiceHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import './styles.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Image from '../../components/Image/Image'

export const Sluzby = ({
  data: { page, image1, image2, yellowArrow },
  pageContext
}) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={'Služby'} />
        <div className="container">
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-lg-12">
              <h2 className="PageWrapper">S čím vám můžeme pomoci?</h2>
            </div>
            <div className="col-md-6">
              {!!image1 && !!image1.childImageSharp ? (
                <Img
                  className="PictureRadius1"
                  fluid={image1.childImageSharp.fluid}
                  alt="sluzby-1"
                />
              ) : (
                <img src={image1.src} alt="sluzby-1" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox1">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Zastoupíme vás při daňových kontrolách</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Pomůžeme v komunikaci s finančními i celními úřady</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Podáme za vás odvolání proti platebnímu výměru</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Připravíme správní žalobu</p>
                </div>
              </div>
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              <div className="BlueBox2">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Pomůžeme s vrácením nadměrného odpočtu DPH</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Pomůžeme vám s odstraněním pochybností ze strany finančního
                    úřadu
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Obdrželi jste výzvu z finančního úřadu? Pomůžeme vám s
                    odpovědí
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {!!image2 && !!image2.childImageSharp ? (
                <Img
                  className="PictureRadius2"
                  fluid={image2.childImageSharp.fluid}
                  alt="sluzby-2"
                />
              ) : (
                <img src={image2.src} alt="sluzby-2" />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Sluzby

export const pageQuery = graphql`
  query Sluzby {
    page: markdownRemark(frontmatter: { slug: { eq: "sluzby" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "sluzby1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "sluzby2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellowArrow: file(relativePath: { eq: "yellow-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
